import { NextPage } from 'next';

import { Footer } from '@/src/shared/ui-kit/components/footer';

import styles from './gifts-coming-soon.module.scss';

export const GiftsComingSoon: NextPage = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1 className="titleText">Ollie Gifts - coming soon</h1>
        <span className={styles.subhead}>
          Ollie Gifts is now under maintenance
        </span>
      </div>

      <Footer className={styles.footer} />
    </div>
  );
};
