import Head from 'next/head';
import Image from 'next/image';

export interface IHeadingTemplateProps {
  title?: string;
  description?: string;

  themeColor?: string;
  imageSrc?: string;
}

export const HeadingTemplate: React.FC<IHeadingTemplateProps> = ({
  title = 'Ollie - your personal shopping assistant',
  description = 'Your personal shopper',
  themeColor = '#fbf6ec',
  imageSrc = '/assets/landing-bag.png',
}) => {
  return (
    <>
      <Head>
        <meta property="og:image" content={imageSrc} />

        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />

        {/* <!-- Primary Meta Tags --> */}
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ollie.ai/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ollie.ai/" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageSrc} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="https://ollie.ai/" />
        <meta name="twitter:creator" content="@shopwithollieai" />

        <meta name="theme-color" content={themeColor} />
      </Head>

      <Image
        width={100}
        height={100}
        src={imageSrc}
        alt="Preview image"
        style={{ display: 'none' }}
      />
    </>
  );
};
