import Link from 'next/link';
import { FaXTwitter } from 'react-icons/fa6';

import styles from './social-icons.module.scss';
import { BsInstagram } from 'react-icons/bs';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterest,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { SiMedium } from 'react-icons/si';

export const SocialIcons: React.FC<{ className?: string }> = ({
  className = '',
}) => (
  <ul className={`${className} ${styles.actions}`}>
    {/* <li>
      <Link prefetch={false} target="_blank" href="https://twitter.com/shopwithollieai">
        <FaXTwitter className="icon" />
      </Link>
    </li> */}

    {/* <li>
      <Link prefetch={false} target="_blank" href="https://www.pinterest.com/shopwithollieai">
        <FaPinterest className="icon" />
      </Link>
    </li> */}

    <li>
      <Link
        prefetch={false}
        target="_blank"
        href="https://www.facebook.com/hiollieai"
      >
        <FaFacebookF className="icon" />
      </Link>
    </li>

    <li>
      <Link
        prefetch={false}
        target="_blank"
        href="https://www.instagram.com/heyollieai"
      >
        <BsInstagram className="icon" />
      </Link>
    </li>

    <li>
      <Link
        prefetch={false}
        target="_blank"
        href="https://tiktok.com/@heyollieai"
      >
        <FaTiktok className="icon" />
      </Link>
    </li>

    <li>
      <Link
        prefetch={false}
        target="_blank"
        href="https://www.youtube.com/@heyollieai"
      >
        <FaYoutube className="icon" />
      </Link>
    </li>
    {/*
    <li>
      <Link prefetch={false} target="_blank" href="https://medium.com/@shopwithollie.ai">
        <SiMedium className="icon" />
      </Link>
    </li> */}

    <li>
      <Link
        prefetch={false}
        target="_blank"
        href="https://www.linkedin.com/company/heyollie"
      >
        <FaLinkedinIn className="icon" />
      </Link>
    </li>
  </ul>
);
