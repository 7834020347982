import React from 'react';
import { NextPage } from 'next';

import { HeadingTemplate } from '@/src/components/layouts/heading-template/heading-template.component';
import { GiftsComingSoon } from '@/src/components/pages/gifts-coming-soon';

const Home: NextPage = (props) => {
  return (
    <>
      <HeadingTemplate />

      <GiftsComingSoon {...props} />
    </>
  );
};

export default Home;
